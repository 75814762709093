import mock from '@/connection-api/mock'
/* eslint-disable global-require */
const data = {
  accountSetting: {
    general: {
      avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
      username: 'lee',
      fullName: 'Lee',
      email: 'lee@gmail.com',
      company: '124215215125125',
      usia: 32,
      tahun: 1986,
      gender: 'Laki-laki',
      alamat: 'Jl. Salabenda Ampera RT 04/06 Desa Parakan Jaya Kecamatan Kemang',
      statusmenikah: 'Menikah, memiliki anak',
      hp: '6281319308889',
    },
    info: {
      bio: '',
      dob: '1986-01-01',
      country: 'Indonesia',
      website: '',
      phone: '6281319308889',
      provinsi: 'Jawa Barat',
      kabkota: 'Bogor',
      desa: 'Desa Parakan Jaya',
      kodepos: '16310',
      pendidikan: 'S1',
      statuspekerjaan: 'Karyawan',
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: '',
        google: '',
        linkedIn: 'https://www.linkedin.com',
        instagram: '',
        quora: '',
      },
      connections: {
        twitter: {
          profileImg: require('@/assets/images/avatars/11-small.png'),
          id: 'lee',
        },
        google: {
          profileImg: require('@/assets/images/avatars/3-small.png'),
          id: 'lee',
        },
        facebook: {},
        github: {},
      },
    },
    notification: {
      commentOnArticle: true,
      AnswerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false,
    },
  },
  kegiatanSetting: {
    general: {
      avatar: '',
      nama: 'Outbound',
      fullName: 'Lee',
      email: 'lee@gmail.com',
      company: '124215215125125',
      usia: 32,
      tahun: 1987,
      lokasi: 'Cikole, Lembang',
      tanggal: '2021-01-01',
      bio: '',
    },
    info: {
      bio: '',
      dob: '1987-01-01',
      country: 'Indonesia',
      website: '',
      phone: '6281319308883',
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: '',
        google: '',
        linkedIn: 'https://www.linkedin.com',
        instagram: '',
        quora: '',
      },
      connections: {
        twitter: {
          profileImg: require('@/assets/images/avatars/11-small.png'),
          id: 'lee',
        },
        google: {
          profileImg: require('@/assets/images/avatars/3-small.png'),
          id: 'lee',
        },
        facebook: {},
        github: {},
      },
    },
    notification: {
      commentOnArticle: true,
      AnswerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false,
    },
  },
  proyekSetting: {
    general: {
      avatar: '',
      nama: 'Outbound',
      fullName: 'Lee',
      email: 'lee@gmail.com',
      company: '124215215125125',
      usia: 32,
      tahun: 1987,
      lokasi: 'Cikole, Lembang',
      tanggal: '2021-01-01',
      bio: '',
      status: 'Aktif',
    },
    info: {
      bio: '',
      dob: '1987-01-01',
      country: 'Indonesia',
      website: '',
      phone: '6281319308883',
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: '',
        google: '',
        linkedIn: 'https://www.linkedin.com',
        instagram: '',
        quora: '',
      },
      connections: {
        twitter: {
          profileImg: require('@/assets/images/avatars/11-small.png'),
          id: 'lee',
        },
        google: {
          profileImg: require('@/assets/images/avatars/3-small.png'),
          id: 'lee',
        },
        facebook: {},
        github: {},
      },
    },
    notification: {
      commentOnArticle: true,
      AnswerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false,
    },
  },
}
/* eslint-disable global-require */
mock.onGet('/account-setting/data').reply(() => [200, data.accountSetting])
mock.onGet('/kegiatan/data').reply(() => [200, data.kegiatanSetting])
mock.onGet('/proyek/data').reply(() => [200, data.proyekSetting])
