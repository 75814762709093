import {
  get, post, hapus, update, upload,
} from './helpers'

function getAllUser() {
  return get('/user/list')
}

function getUser(UserId) {
  return get(`/user/${UserId}`)
}

function getNamaFile() {
  return get('/master/log-import/namaFile')
}

function getStatus() {
  return get('/master/log-import/status')
}

function insUser(data) {
  return post('/user', data)
}

function ubahUser($data) {
  return update('/user', $data)
}

function uploadFileUser(file, metadata, onUploadProgress) {
  return upload('/user/uploadfile', file, metadata, onUploadProgress)
}

function deleteFaq($data) {
  return post('/faq/delete', $data)
}

function changePassword($data) {
  return update('/change-password', $data)
}

function forgotPassword($data) {
  return post('/lupa-password', $data)
}

function resetPassword($data) {
  return post('/reset-password', $data)
}

function deleteKategoriKeterampilan($data) {
  return post('/kategori-keterampilan/delete', $data)
}

function deleteKategoriFaq($data) {
  return post('/kategori-faq/delete', $data)
}

function deleteAplikasi($data) {
  return post('/aplikasi/delete', $data)
}

function deleteStatusPernikahan($data) {
  return post('/status-pernikahan/delete', $data)
}

function deleteStatusPekerjaan($data) {
  return post('/status-pekerjaan/delete', $data)
}

function deleteJenisProgram($data) {
  return post('/jenis-program/delete', $data)
}

function deleteJenisDisabilitas($data) {
  return post('/jenis-disabilitas/delete', $data)
}

function deleteJenisPendidikan($data) {
  return post('/jenis-pendidikan/delete', $data)
}

function deleteTag($data) {
  return post('/tag/delete', $data)
}

function verify($data) {
  return post('/user/verify', $data)
}

function softDeleteUser($data) {
  return post('/pengguna/delete', $data)
}

function checkExpired($data) {
  return get('/check/token', $data)
}

/* eslint-disable import/prefer-default-export */
export {
  getAllUser,
  getUser,
  insUser,
  ubahUser,
  uploadFileUser,
  deleteFaq,
  changePassword,
  forgotPassword,
  resetPassword,
  deleteKategoriKeterampilan,
  deleteKategoriFaq,
  deleteAplikasi,
  deleteStatusPernikahan,
  deleteStatusPekerjaan,
  deleteJenisProgram,
  deleteJenisDisabilitas,
  deleteJenisPendidikan,
  verify,
  deleteTag,
  getNamaFile,
  getStatus,
  softDeleteUser,
  checkExpired,
}
