// https://medium.com/@imanhodjaev/handling-api-calls-in-vue-cf39747656ba
// https://github.com/FullTimeVue/vue-rest-api-sample/blob/master/src/helpers/http.js

import axios from 'axios'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL

/*
 * Returns default headers list
 * which will be used with every request.
 */
function getHeaders(multipart = false) {
  let defaultHeaders = DEFAULT_HEADERS

  if (multipart) {
    defaultHeaders = {}
  }
  const token = localStorage.getItem('accessToken')
  if (token) {
    defaultHeaders = {
      Authorization: `bearer ${token}`,
      // 'Content-type': 'application/json',
      // 'Content-Type': 'multipart/form-data',
      ...defaultHeaders,
    }
  }
  return defaultHeaders
}

// Just a convenient shorthand
export const esc = encodeURIComponent

// Returns formatted query string from object
// export function qs(params) {
//   return (
//     Object
//       .keys(params)
//       // .map(k => esc(k) + '=' + esc(params[k]))
//       .map(k => esc(k) + '=' + esc(params[k]))
//       .join('&')
//   )
// }

/*
 * Wraps axios and provides
 * more convenient get method
 * calls with data.
 */
// export function get(uri, data = {}) {
export function get(uri, data) {
  // if (Object.keys(data).length > 0) {
  //   uri = `${uri}?${qs(data)}`
  // }
  return axios.get(uri, {
    params: data,
    headers: getHeaders(),
    // withCredentials: true
  })
}

// export function hapus(uri) {
//   return axios.delete(uri)
// }

export function hapus(uri, data) {
  return axios.delete(uri, {
    params: data,
    headers: getHeaders(),
  })
}

// export function hapus(uri, data) {
//   return axios.delete(uri, data)
// }

export function post(uri, data) {
  return axios.post(uri, data, {
    headers: getHeaders(),
    // withCredentials: true
  })
  // .then(response => (console.log(response.data)))
  // .catch(error => console.log(error))
}

export function postNoHeader(uri, data) {
  return axios.post(uri, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    // withCredentials: true
  })
}

export function update(uri, data) {
  return axios.patch(uri, data, {
    headers: getHeaders(),
    // withCredentials: true
  })
}

export function upload(uri, file, directory, onUploadProgress) {
  const formData = new FormData()
  formData.append('File', file)
  formData.append('directory', directory)
  const token = localStorage.getItem('accessToken')
  return axios.post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
    },
    onUploadProgress,
  })
}

export function uploadProyekId(uri, file, directory, onUploadProgress, id) {
  const formData = new FormData()
  formData.append('File', file)
  formData.append('directory', directory)
  formData.append('id', id)
  const token = localStorage.getItem('accessToken')
  return axios.post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
    },
    onUploadProgress,
  })
}

export function image(uri) {
  return axios.get(uri, {
    headers: getHeaders(),
    responseType: 'blob',
    // withCredentials: true
  })
}

export function download(uri, data) {
  return axios.get(uri, {
    params: data,
    headers: getHeaders(),
    responseType: 'blob',
    // withCredentials: true
  })
}
