export default [
  {
    path: '/projek/list',
    name: 'pages-project',
    component: () => import('@/views/projek/list/List.vue'),
  },
  {
    path: '/pages/projek/:id',
    name: 'pages-projek-detail',
    component: () => import('@/views/projek/ProjekDetail.vue'),
    meta: {
      pageTitle: 'Detail Proyek',
      breadcrumb: [
        {
          text: 'Proyek',
          to: '/projek/list',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/kegiatan/:id',
    name: 'pages-kegiatan-detail',
    component: () => import('@/views/kegiatan/KegiatanDetail.vue'),
    meta: {
      pageTitle: 'Kegiatan Detail',
      breadcrumb: [
        {
          text: 'Kegiatan',
          to: '/kegiatan/list',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kegiatan/list',
    name: 'pages-kegiatan',
    component: () => import('@/views/kegiatan/list/List.vue'),
  },
  {
    path: '/laporan-kegiatan/list',
    name: 'laporan-kegiatan',
    component: () => import('@/views/laporan-kegiatan/list/List.vue'),
  },
  {
    path: '/kegiatan/tambah',
    name: 'pages-kegiatan-tambah',
    component: () => import('@/views/kegiatan/Create.vue'),
  },
  {
    path: '/kegiatan/ubah/:id',
    name: 'pages-kegiatan-ubah',
    component: () => import('@/views/kegiatan/Create.vue'),
  },
  {
    path: '/proyek/tambah',
    name: 'pages-proyek-tambah',
    component: () => import('@/views/projek/Create.vue'),
  },
  {
    path: '/proyek/ubah/:id',
    name: 'pages-proyek-ubah',
    component: () => import('@/views/projek/Create.vue'),
  },
  // *===============================================---*
  // *--------- PROJECT -------------------------------*
  // *===============================================---*
  {
    path: '/daftar-user-kegiatan/list',
    name: 'report-list-kegiatan',
    component: () => import('@/views/daftar-user-kegiatan/list/List.vue'),
  },
  {
    path: '/daftar-user-project/list',
    name: 'report-list-proyek',
    component: () => import('@/views/daftar-user-project/list/List.vue'),
  },
  {
    path: '/pengguna/list',
    name: 'pengguna-list',
    component: () => import('@/views/user/list/List.vue'),
  },
  {
    path: '/pengguna/edit/:id',
    name: 'pengguna-edit',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Ubah Data Pengguna',
      breadcrumb: [
        {
          text: 'Pengguna',
          to: '/pengguna/list',
        },
        {
          text: 'Ubah Data Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kategori-keterampilan/list',
    name: 'kategori-keterampilan',
    component: () => import('@/views/kategori-keterampilan/list/List.vue'),
  },
  {
    path: '/kategori-keterampilan/edit/:id',
    name: 'kategori-keterampilan-edit',
    component: () => import('@/views/kategori-keterampilan/edit/Edit.vue'),
  },
  {
    path: '/kategori-faq/list',
    name: 'kategori-faq',
    component: () => import('@/views/kategori-faq/list/List.vue'),
  },
  {
    path: '/kategori-faq/edit/:id',
    name: 'kategori-faq-edit',
    component: () => import('@/views/kategori-faq/edit/Edit.vue'),
  },
  {
    path: '/status-pernikahan/list',
    name: 'status-pernikahan',
    component: () => import('@/views/status-pernikahan/list/List.vue'),
  },
  {
    path: '/status-pernikahan/edit/:id',
    name: 'status-pernikahan-edit',
    component: () => import('@/views/status-pernikahan/edit/Edit.vue'),
  },
  {
    path: '/status-pekerjaan/list',
    name: 'status-pekerjaan',
    component: () => import('@/views/status-pekerjaan/list/List.vue'),
  },
  {
    path: '/status-pekerjaan/edit/:id',
    name: 'status-pekerjaan-edit',
    component: () => import('@/views/status-pekerjaan/edit/Edit.vue'),
  },
  {
    path: '/jenis-program/list',
    name: 'jenis-program',
    component: () => import('@/views/jenis-program/list/List.vue'),
  },
  {
    path: '/jenis-program/edit/:id',
    name: 'jenis-program-edit',
    component: () => import('@/views/jenis-program/edit/Edit.vue'),
  },
  {
    path: '/jenis-disabilitas/list',
    name: 'jenis-disabilitas',
    component: () => import('@/views/jenis-disabilitas/list/List.vue'),
  },
  {
    path: '/jenis-disabilitas/edit/:id',
    name: 'jenis-disabilitas-edit',
    component: () => import('@/views/jenis-disabilitas/edit/Edit.vue'),
  },
  {
    path: '/jenis-pendidikan/list',
    name: 'jenis-pendidikan',
    component: () => import('@/views/jenis-pendidikan/list/List.vue'),
  },
  {
    path: '/jenis-pendidikan/edit/:id',
    name: 'jenis-pendidikan-edit',
    component: () => import('@/views/jenis-pendidikan/edit/Edit.vue'),
  },
  {
    path: '/tag/list',
    name: 'tag',
    component: () => import('@/views/tag/list/List.vue'),
  },
  {
    path: '/tag/edit/:id',
    name: 'tag-edit',
    component: () => import('@/views/tag/edit/Edit.vue'),
  },
  {
    path: '/aplikasi/list',
    name: 'aplikasi',
    component: () => import('@/views/aplikasi/list/List.vue'),
  },
  {
    path: '/aplikasi/edit/:id',
    name: 'aplikasi-edit',
    component: () => import('@/views/aplikasi/edit/Edit.vue'),
  },
  {
    path: '/pengguna/rules/list',
    name: 'pengguna-rules-list',
    component: () => import('@/views/rules/list/List.vue'),
  },
  {
    path: '/rules/edit/:id',
    name: 'rules-edit',
    component: () => import('@/views/rules/edit/Edit.vue'),
  },
  {
    path: '/pages/faq',
    name: 'faq-view',
    component: () => import('@/views/pages/faq/Faq.vue'),
  },
  {
    path: '/kelola-keterampilan',
    name: 'kelola-keterampilan',
    component: () => import('@/views/keterampilan/list/List.vue'),
  },
  {
    path: '/kelola-keterampilan/edit/:id',
    name: 'kelola-keterampilan-edit',
    component: () => import('@/views/keterampilan/edit/Edit.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/faq/list/List.vue'),
  },
  {
    path: '/faq/edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/pages/faq/edit/Edit.vue'),
  },
  {
    path: '/keterampilan',
    name: 'keterampilan',
    component: () => import('@/views/keterampilan/list/List.vue'),
  },
  {
    path: '/keterampilan/edit/:id',
    name: 'keterampilan-edit',
    component: () => import('@/views/keterampilan/edit/Edit.vue'),
  },
  {
    path: '/master/jenis-kegiatan/list',
    name: 'master-jenis-kegiatan-list',
    component: () => import('@/views/jenis-kegiatan/list/List.vue'),
  },
  {
    path: '/log-import/list',
    name: 'log-import-list',
    component: () => import('@/views/log-import/list/List.vue'),
  },
  {
    path: '/log-mail/list',
    name: 'log-mail-list',
    component: () => import('@/views/log-mail/list/List.vue'),
  },
  {
    path: '/jenis-kegiatan/aset/edit/:id',
    name: 'jenis-kegiatan-edit',
    component: () => import('@/views/jenis-kegiatan/edit/Edit.vue'),
  },
  {
    path: '/master/jenis-dokumen/list',
    name: 'master-jenis-dokumen-list',
    component: () => import('@/views/jenis-dokumen/list/List.vue'),
  },
  {
    path: '/jenis/dokumen/edit/:id',
    name: 'jenis-dokumen-edit',
    component: () => import('@/views/jenis-dokumen/edit/Edit.vue'),
  },
  {
    path: '/penggunaan',
    name: 'penggunaan',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  },
  {
    path: '/livechat',
    name: 'livechat',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  },
  {
    path: '/kontak-admin',
    name: 'kontak-admin',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  },
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },
  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   component: () => import('@/views/chat/Simple.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },
  // {
  //   path: '/chat/:id',
  //   name: 'chat-detail',
  //   component: () => import('@/views/chat/Simple.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
