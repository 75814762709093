import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = null
if (localStorage.getItem('userData') !== 'undefined') {
  const userData = JSON.parse(localStorage.getItem('userData'))
  existingAbility = userData ? userData.ability : null
} else {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')

  // Remove userData from localStorage
  localStorage.removeItem('userData')
}
export default new Ability(existingAbility || initialAbility)
