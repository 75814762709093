import mock from '@/connection-api/mock'
/* eslint-disable global-require */
const data = {
  // blog list
  kegiatanList: [
    /* {
      id: 4,
      img: require('@/assets/images/slider/03.jpg'),
      title: 'Withhold a Compelling Piece of Information',
      avatar: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
      userFullName: 'Fred Boone',
      blogPosted: 'Jan 10, 2021',
      tags: ['Video'],
      excerpt: 'Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.',
      comment: 10,
    },
    {
      id: 5,
      img: require('@/assets/images/slider/09.jpg'),
      title: 'Unadvertised Bonus Opening: Share a Quote',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Billy French',
      blogPosted: 'Jan 10, 2021',
      tags: ['Quote', 'Fashion'],
      excerpt: 'Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.',
      comment: 319,
    },
    {
      id: 6,
      img: require('@/assets/images/slider/10.jpg'),
      title: 'Ships at a distance have Every Man’s Wish on Board',
      avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      userFullName: 'Helena Hunt',
      blogPosted: 'Jan 10, 2021',
      tags: ['Fashion', 'Video'],
      excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      comment: 1500,
    }, */
  ],

  // sidebar
  projekSidebar: {
    recentPosts: [
    ],
  },
  blogSidebar: {
    recentPosts: [
    ],
    categories: [
      { category: 'Fashion', icon: 'WatchIcon' },
      { category: 'Food', icon: 'ShoppingCartIcon' },
      { category: 'Gaming', icon: 'CommandIcon' },
      { category: 'Quote', icon: 'HashIcon' },
      { category: 'Video', icon: 'VideoIcon' },
    ],
  },

  // detail
  blogDetail: {
  },

  projekDetail: {
  },

  kegiatanDetail: {
  },

  // edit
  blogEdit: {
  },
}
/* eslint-disable global-require */
mock.onGet('/blog/list/data').reply(() => [200, data.blogList])
mock.onGet('/blog/list/project').reply(() => [200, data.projectList])
mock.onGet('/blog/list/course').reply(() => [200, data.courseList])
mock.onGet('/blog/list/kegiatan').reply(() => [200, data.kegiatanList])
mock.onGet('/blog/list/data/sidebar').reply(() => [200, data.blogSidebar])
mock.onGet('/blog/list/data/detail').reply(() => [200, data.blogDetail])
mock.onGet('/blog/list/data/projekdetail').reply(() => [200, data.projekDetail])
mock.onGet('/blog/list/data/projeksidebar').reply(() => [200, data.projekSidebar])
mock.onGet('/blog/list/data/kegiatandetail').reply(() => [200, data.kegiatanDetail])
mock.onGet('/blog/list/data/kegiatansidebar').reply(() => [200, data.kegiatanSidebar])
mock.onGet('/blog/list/data/edit').reply(() => [200, data.blogEdit])
