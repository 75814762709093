import mock from '@/connection-api/mock'
/* eslint-disable global-require */
const data = {
  profileData: {
    header: {
      avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      username: 'Rara',
      designation: 'UI/UX Designer',
      coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
    },
    userAbout: {
      biografi: 'Integer et bibendum est. Donec auctor blandit tincidunt. Suspendisse potenti. ',
      tanggal_bergabung: 'November 15, 2018',
      umur: '28',
      email: 'rara@gmail.com',
      jenis_kelamin: 'Perempuan',
      hp: '081319308***',
    },
    suggestedPages: [
      {
        avatar: require('@/assets/images/avatars/12-small.png'),
        username: 'Soft Skill',
        subtitle: 'Biasa',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/avatars/1-small.png'),
        username: 'Memasak',
        subtitle: 'Bagus',
        favorite: false,
      },
      {
        avatar: require('@/assets/images/avatars/10-small.png'),
        username: 'Musik',
        subtitle: 'Menengah',
        favorite: false,
      },
      // {
      //   avatar: require('@/assets/images/avatars/3-small.png'),
      //   username: 'Claudia Chandler',
      //   subtitle: 'Company',
      //   favorite: false,
      // },
      // {
      //   avatar: require('@/assets/images/avatars/5-small.png'),
      //   username: 'Earl Briggs',
      //   subtitle: 'Company',
      //   favorite: true,
      // },
      // {
      //   avatar: require('@/assets/images/avatars/6-small.png'),
      //   username: 'Jonathan Lyons',
      //   subtitle: 'Beauty Store',
      //   favorite: false,
      // },
    ],
    twitterFeeds: [
      {
        imgUrl: require('@/assets/images/avatars/5-small.png'),
        title: 'Gertrude Stevens',
        id: 'tiana59 ',
        tags: '#design #fasion',
        desc: 'I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.',
        favorite: false,
      },
      {
        imgUrl: require('@/assets/images/avatars/12-small.png'),
        title: 'Lura Jones',
        id: 'tiana59 ',
        tags: '#vuejs #code #coffeez',
        desc: 'Halvah I love powder jelly I love cheesecake cotton candy. 😍',
        favorite: true,
      },
      {
        imgUrl: require('@/assets/images/avatars/1-small.png'),
        title: 'Norman Gross',
        id: 'tiana59 ',
        tags: '#sketch #uiux #figma',
        desc: 'Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.',
        favorite: false,
      },
    ],
    post: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-18.jpg'),
        username: 'Leeanna Alvord',
        postTime: '12 Dec 2018 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postImg: require('@/assets/images/profile/post-media/2.jpg'),
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedUsers: [
          { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
        ],
        likedCount: 140,
        detailedComments: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            commentsLikes: 34,
            youLiked: false,
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            username: 'Jackey Potter',
            comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
            commentsLikes: 61,
            youLiked: true,
          },
        ],
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-22.jpg'),
        username: 'Rosa Walters',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postImg: require('@/assets/images/profile/post-media/25.jpg'),
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedUsers: [
          { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
        ],
        likedCount: 271,
        detailedComments: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            commentsLikes: 34,
            youLiked: false,
          },
        ],
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
        username: 'Charles Watson',
        postTime: '12 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        postVid: 'https://www.youtube.com/embed/6stlCkUDG_s',
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedUsers: [
          { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
        ],
        likedCount: 264,
        detailedComments: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
            username: 'Kitty Allanson',
            comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
            commentsLikes: 34,
            youLiked: false,
          },
        ],
      },
    ],
    latestPhotos: [
      { img: require('@/assets/images/profile/user-uploads/user-13.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-02.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-03.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-04.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-05.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-06.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-07.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-08.jpg') },
      { img: require('@/assets/images/profile/user-uploads/user-09.jpg') },
    ],
    suggestions: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
        name: 'Peter Reed',
        mutualFriend: '6 Mutual Friends',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        name: 'Harriett Adkins',
        mutualFriend: '3 Mutual Friends',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
        name: 'Juan Weaver',
        mutualFriend: '1 Mutual Friends',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
        name: 'Claudia Chandler',
        mutualFriend: '16 Mutual Friends',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
        name: 'Earl Briggs',
        mutualFriend: '4 Mutual Friends',
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
        name: 'Jonathan Lyons',
        mutualFriend: '25 Mutual Friends',
      },
    ],
    polls: [
      {
        name: 'Apakah kegiatan kemarin dapat dimengerti?',
        answer: [
          {
            name: 'Ya',
            result: '67%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
          {
            name: 'Tidak',
            result: '33%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
        ],
      },
      {
        name: 'Apa yang Anda dapatkan dalam kegiatan kemarin?',
        answer: [
          {
            name: 'Lebih Percaya Diri',
            result: '67%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
          {
            name: 'Secara teori materi mudah untuk diterapkan',
            result: '29%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
          {
            name: 'Bingung',
            result: '4%',
            votedUser: [
              { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), username: 'Tonia Seabold' },
              { img: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Carissa Dolle' },
              { img: require('@/assets/images/portrait/small/avatar-s-8.jpg'), username: 'Jonathan Lyons' },
            ],
          },
        ],
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/profile/data').reply(() => [200, data.profileData])
