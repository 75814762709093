import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import i18n from '@/libs/i18n'

import {
  checkExpired,
} from '@/api/user'

import { AuthLogout } from '@/connection-api/authentication'
import { initialAbility } from '@/libs/acl/config'

import Echo from 'laravel-echo'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/connection-api/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Alter BY EA - 25/05/2022 - Menambahkan recaptcha
const recaptchaEnabled = process.env.VUE_APP_RECAPTCHAV3
if (recaptchaEnabled === 'true') {
  Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHAV3_KEY })
}
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

window.Pusher = require('pusher-js')

const token = localStorage.getItem('accessToken')
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}
let defaultHeaders = DEFAULT_HEADERS
if (token) {
  defaultHeaders = {
    Authorization: `bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    // 'Content-type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    ...defaultHeaders,
  }
}
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'anyKey',
  wsHost: process.env.VUE_APP_WS_HOST,
  authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: defaultHeaders,
  },
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WS_PORT,
  encrypted: true,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  disableStats: true,
})

new Vue({
  router,
  store,
  i18n,
  async created() {
    const response = await checkExpired()
    if (response.data.code === 0 || response.data.status === 'Token is Invalid') {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      AuthLogout()
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  },
  render: h => h(App),
}).$mount('#app')
